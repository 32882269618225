<template>
  <div class="terminal-ticket-details-wrapper">
    <TerminalTicketDetails
      v-if="loaded && ticketData"
      :ticket="ticketData"
      :cancelEnabled="!userIsOperator"
      :rebetEnabled="rebetEnabled"
      @payout="handleChange(rawTicketData)"
      @cancel="handleCancel(rawTicketData)"
      />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { eventBus, TerminalTicketDetails, ticketMapper } from '@nsftx/games-sdk-js';

export default {
  name: 'Terminal',
  components: {
    TerminalTicketDetails,
  },
  data() {
    return {
      rebetEnabled: false,
      ticketData: null,
      rawTicketData: null,
    };
  },
  computed: {
    ...mapGetters([
      'ticketDetailsData',
      'config',
      'translations',
      'loaded',
      'terminalUserData',
      'userIsOperator',
    ]),
  },
  methods: {
    ...mapActions([
      'setLoaded',
    ]),
    handleChange(ticket) {
      eventBus.$emit('PayoutTicket', ticket);
    },
    handleCancel(ticket) {
      eventBus.$emit('TicketCancel', ticket);
    },
  },
  watch: {
    ticketDetailsData(val) {
      this.setLoaded(true);
      const formatData = ticketMapper.mapTicket(val);
      this.rawTicketData = val;
      this.ticketData = formatData;
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "NFont";
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot");
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot?#iefix") format("embeded-opentype"),
  url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.woff") format("woff"),
  url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.n-i {
  font-family: "NFont";
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-won:before {
  content: "\e63b";
}
.icon-open:before {
  content: "\e664";
}
.icon-lost:before {
  content: "\e612";
}
</style>
